import { registerApplication, start, navigateToUrl } from "single-spa"
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine
} from "single-spa-layout"
import microfrontendLayout from "./microfrontend-layout.html"
import {
  coreStore,
  SET_ACTIVE_APP,
  SET_INITIAL_LOAD,
  AUTO_LOGIN,
  SET_LOADER_STATUS,
  SET_PAGE_TITLE,
  SET_ACTIVE_MENU,
  SET_SETTINGS_TYPE,
  SET_APP_TITLE
} from "@forcepoint/platform-coreui"
import { isEmpty } from "@forcepoint/platform-utilityui"

const sessionTabKey = "tabCount"

const getCount = (str) => {
  return str && !isNaN(+str) ? +str : 0
}

const onTabClose = () => {
  const str = localStorage.getItem(sessionTabKey)
  const tabCount = getCount(str)
  if (tabCount > 0) {
    localStorage.setItem(sessionTabKey, (+tabCount - 1).toString())
  }
}

const setTabCount = () => {
  const str = localStorage.getItem(sessionTabKey)
  const tabCount = getCount(str)
  localStorage.setItem("isFocused", "true")
  localStorage.setItem(sessionTabKey, (tabCount + 1).toString())
}
// for build
window.addEventListener("beforeunload", function () {
  onTabClose()
})

window.addEventListener("load", function () {
  setTabCount()
})

//this comment for build
// This will allow importmap to be passed as xhr requests
// https://github.com/systemjs/systemjs/blob/main/docs/hooks.md#shouldfetchurl---boolean.
//
System.shouldFetch = function () {
  return true
}

// Passing header with fetch js requests.
// https://github.com/systemjs/systemjs/blob/main/src/extras/module-types.js

System.fetch = function (url, options) {
  options.headers = {
    Authorization: `Bearer ${coreStore.getState()?.userToken}`
  }
  return fetch(url, options)
}

/**
 * Storing routes configuration.
 */
const routes = constructRoutes(microfrontendLayout)

/**
 * Constructing applications.
 */
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name)
  }
})

async function main() {
  if (process.env.NODE_ENV === "development") {
    const { worker } = require("./mocks/browser")
    await worker.start()
  }
}

// main()

/**
 * If its localhost then register localui app
 */
if (window.location.hostname === "localhost") {
  registerApplication({
    name: "@forcepoint/platform-localui",
    app: () => System.import("//localhost:9001/forcepoint-platform-localui.js"),
    activeWhen: "/local-login"
  })
}

/**
 * Constructing layout engine
 */
const layoutEngine = constructLayoutEngine({ routes, applications })

/**
 * Dispatching auto login action
 */
coreStore.dispatch({ type: AUTO_LOGIN })

let flag = true

/**
 * State subscription.
 */
coreStore.subscribe(() => {
  const storeData = coreStore.getState()
  if (storeData.applications.length > 0 && flag) {
    flag = false
    const sharedImports = {} // To store shared imports.
    let defaultUrl = null
    let isHomePresent = false
    for (let app of storeData.applications) {
      if (app.isSPA) {
        // Shared modules import map
        for (let appUrls of app.sharedModules) {
          sharedImports[appUrls.moduleName] =
            appUrls.moduleUrls.prefix + appUrls.moduleUrls.jsPath
        }
        if (
          app.applicationName === "Insights" &&
          app.menus?.length > 0 &&
          !isHomePresent
        ) {
          defaultUrl = app.menus[0].url
        }
        // if (app.applicationName === "Home" && app.menus?.length > 0) {
        //   defaultUrl = app.menus[0].url
        //   isHomePresent = true
        // }
        // To register applications.
        for (let appUrls of app.modules) {
          registerApplication({
            name: appUrls.SPAName,
            app: () =>
              System.import(
                appUrls.moduleUrls.prefix + appUrls.moduleUrls.jsPath
              ),
            activeWhen: (location) =>
              location.pathname.startsWith(appUrls.moduleURLPrefix)
          })
        }
      }
    }

    // Lazyloading of shared map.
    System.addImportMap({
      imports: sharedImports
    })
    const applicationUrls = storeData.applications?.reduce((a, b) => {
      if (b.isSPA && b.menus?.length > 0) {
        const urls = b.menus?.map((a) => a.url)
        a = [...a, ...urls]
      }
      return a
    }, [])
    const currentPathName = window.location.pathname
    if (currentPathName === "/") {
      defaultUrl ? navigateToUrl(defaultUrl) : navigateToUrl(applicationUrls[0])
    } else {
      const findIndex = applicationUrls.findIndex((a) =>
        a.startsWith(currentPathName)
      )
      if (
        findIndex !== -1 &&
        (window.location.hash === "" || window.location.hash === undefined) &&
        window.location.search === ""
      ) {
        navigateToUrl(applicationUrls[findIndex])
      } else if (
        currentPathName === "/logout" ||
        currentPathName === "/401" ||
        currentPathName === "/local-login"
      ) {
        navigateToUrl(applicationUrls[0])
      } else if (currentPathName === "/activation-pending") {
        defaultUrl ? navigateToUrl(defaultUrl) : navigateToUrl(applicationUrls[0])
      }
    }
  }
  if (!isEmpty(storeData?.activeMenu?.localizationKey)) {
    document.title = `${storeData?.activeMenu?.localizationKey}`
  } else if (storeData?.isSettingsPage) {
    document.title = !isEmpty(storeData?.activeMenu?.name)
      ? storeData?.activeMenu?.name
      : storeData?.appTitle
  } else {
    document.title = "F|ONE"
  }
})

/**
 * Event listener before app change.
 */
window.addEventListener("single-spa:before-app-change", (evt) => {
  coreStore.dispatch({ type: SET_ACTIVE_APP, appName: null })
  coreStore.dispatch({ type: SET_PAGE_TITLE, pageTitle: "" })
  const mountedApp = evt.detail.appsByNewStatus.MOUNTED
  if (!mountedApp.includes("@forcepoint/platform-navigationui")) {
    coreStore.dispatch({ type: SET_LOADER_STATUS, status: true })
    coreStore.dispatch({ type: SET_ACTIVE_APP, appName: mountedApp })
  }
})

/**
 * Event listner app change
 */
window.addEventListener("single-spa:app-change", (evt) => {
  const mountedApp = evt.detail.appsByNewStatus.MOUNTED
  if (!mountedApp.includes("@forcepoint/platform-navigationui")) {
    coreStore.dispatch({ type: SET_LOADER_STATUS, status: false })
    // const actApp = coreStore.getState()?.activeApp
    const activeApplicationCode =
      coreStore.getState()?.activeApp?.applicationCode
    const tenantIdList = coreStore.getState()?.userInfo?.ext?.tenantId
    const applicationsList = coreStore.getState()?.applications

    const application = applicationsList?.find(
      (x) => x?.applicationCode === activeApplicationCode
    )
    const tenantId = tenantIdList.find(
      (x) => x?.hint === activeApplicationCode
    )?.id
  
    if (
      !isEmpty(application?.webhooks?.tenantOnboarding) &&
      isEmpty(tenantId)
    ) {
      navigateToUrl("/activation-pending")
    }
  }
})

/**
 * Routing event SPA
 */
window.addEventListener("single-spa:routing-event", (evt) => {
  coreStore.dispatch({ type: SET_ACTIVE_MENU, url: window.location.pathname })
  if (window.location.pathname.includes("settings")) {
    document.getElementsByTagName("body")[0].classList.add("settings-page")
    coreStore.dispatch({ type: SET_SETTINGS_TYPE, status: true })
    coreStore.dispatch({ type: SET_APP_TITLE, appTitle: "Settings" })
    coreStore.dispatch({ type: SET_ACTIVE_MENU, url: window.location.pathname })
  } else {
    document.getElementsByTagName("body")[0].classList.remove("settings-page")
    coreStore.dispatch({ type: SET_SETTINGS_TYPE, status: false })
  }
  if (evt.currentTarget.location.pathname.includes("coming-soon")) {
    coreStore.dispatch({ type: SET_LOADER_STATUS, status: false })
  }
})

/**
 * Registering application and activate layout engine.
 */
applications.forEach(registerApplication)
layoutEngine.activate()
start()
